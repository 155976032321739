<template>
  <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center" >
                    <v-col class="text-left col-md-5">
                        <div class="font-weight-bold body-1">
                            Detalles activo - 
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="5" >

                    <details-item :detailsItems="items"></details-item>


                    </v-col>
                    
                </v-row>

                <v-row justify="center">
                    <v-col cols="12" md="5">
                        <Separator v-if="images.length > 0" title="Fotografías"></Separator>
                        <ImageSlide
                            v-if="images.length > 0"
                            :images="images"
                        ></ImageSlide>
                    </v-col>
                </v-row>

            </div>
		

            <v-row justify="center" align="end">
				<v-col cols="12" md="5" class="d-flex justify-end">
                    
					<primary-button :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
  </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
import Utils from '../../../helpers/Utils';
import ImageSlide from '../../../components/ImageSlide.vue';
import Separator from '../../../components/Separator.vue';
export default {
    components: {
        DetailsItem,
        ImageSlide,
        Separator
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
            images:[
            ],
            items: [
                { concepto: 'Folio', index: 'id', valor: null },
                { concepto: 'Modelo', index: 'model', valor: null},
                { concepto: 'Marca', index: 'brand', valor: null},
                { concepto: 'No. de serie', index: 'serial_number', valor: null},
                { concepto: 'Color', index: 'color', valor: null},
                { concepto: 'Comentarios', index: 'comments', valor: null},
                { concepto: 'Responsable', index: 'employee.name', valor: null},
                { concepto: 'Empresa', index: 'employee.current_contract.branch_office.company.name'}
            ],
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "Inventory",	
                block:false,	
                click: () => {}
            },
         
          
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.index();
    },
	methods: {
         onResize () {
            
            if(window.innerWidth < 600){
                this.cancelBtnData.block = true;
            }
            else{
                this.cancelBtnData.block = false;
            }
        },
		validate () {
			return this.$refs.form.validate()
		},
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.inventory.get(this.id)
			.then((response) => {
                this.items.forEach((y) => {
                    y.valor = Utils.getObjectValue(response.data,y.index)??'Sin asignar';
                });
                // this.items= [
                //     { concepto: 'Nombre', index: 'name', valor: response.data.name },
                //     { concepto: 'Empresa', index: 'company.name', valor: response.data.company.name},
                    
                // ];

                response.data.photos.forEach(item => {
                    this.images.push({url: item.photo_url});
                });
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 