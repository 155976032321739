<template>
    <div>
         <!-- <v-row>
            <v-col>
                <datePickerForm
                    label="Fecha de compra"
                    :valueInput="values.date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.date = v; delete serverErrors.date}"
                    :errorMessages="serverErrors.date"
                ></datePickerForm>
            </v-col>
            
        </v-row> -->

        <v-row>
            <v-col>
                <autocompleteOneForm
                    label="Tipo de activo"
                    :valueInput="values.type"
                    :rule="rules.required"
                    :items="serverItems.types"
                    :errorMessages="serverErrors.type"
                    @valueChange="(v) => {values.type = v; delete serverErrors.type}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
   
        <v-row>
            <v-col>
                <textFieldForm 
                    @valueChange="(v) => {values.description = v.trim(); delete serverErrors['description']}" 
                    :valueInput="values.description" 
                    :rule="rules.required" 
                    label="Descripción"
                    :errorMessages="serverErrors['description']"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <textFieldForm 
                    @valueChange="(v) => {values.model = v.trim(); delete serverErrors['model']}" 
                    :valueInput="values.model" 
                    :rule="rules.required" 
                    label="Modelo"
                    :errorMessages="serverErrors['model']"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <textFieldForm 
                    @valueChange="(v) => {values.brand = v.trim(); delete serverErrors['brand']}" 
                    :valueInput="values.brand" 
                    :rule="rules.required" 
                    label="Marca"
                    :errorMessages="serverErrors['brand']"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <textFieldForm 
                    @valueChange="(v) => {values.serial_number = v.trim(); delete serverErrors['serial_number']}" 
                    :valueInput="values.serial_number" 
                    :rule="rules.required" 
                    label="No. De serie"
                    :errorMessages="serverErrors['serial_number']"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <textFieldForm 
                    @valueChange="(v) => {values.color = v.trim(); delete serverErrors['color']}"
                    :valueInput="values.color" 
                    :rule="rules.required" 
                    label="Color"
                    :errorMessages="serverErrors['color']"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <textFieldForm 
                    @valueChange="(v) => {values.comments = v.trim(); delete serverErrors['comments']}" 
                    :valueInput="values.comments" 
                    :rule="rules.required" 
                    label="Comentarios"
                    :errorMessages="serverErrors['comments']"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
              <fileInputForm
                label="Fotografías"
                :multiple="true"
                :valueInput="values.photo_files"
                :url="''"
                :rule="rules.fileRequired"
                @valueChange="(v) => {values.photo_files = v; delete serverErrors.photo_files}"
                :errorMessages="serverErrors.photo_files"
                v-on:update:url="values.files_url = $event"
                ></fileInputForm>
            </v-col>
        </v-row>

        <v-row v-for="(item, index) in values.imgs" :key="index">
            <v-col>
                <imagePrevForm
                    :nameImg="item.name"
                    :url="item.url"
                    v-on:update:url="item.url = $event; removeImg(index);"
                ></imagePrevForm>
            </v-col>
        </v-row>

               
               
               
               
               

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
   
    data(){
        return {
            serverItems:{
                types:[
                    {text: "Equipo de cómputo", value: "EQUIPO DE CÓMPUTO"},
                    {text: "Periférico", value: "PERIFÉRICO"},
                    {text: "Equipo móvil", value: "EQUIPO MÓVIL"},
                ]
            },
            rules: {
                required: [
					v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
                fileRequired: [
                    v => {
                        if(this.values.photo_files.length == 0 && this.values.file_url == "")
                            return "El campo es requerido"
                        return true
                    }
                ]
				
            },
            
        }
    },
   
    mounted(){
       
    },
    methods: {
        removeImg(index)
        {
            let deleted = JSON.parse(JSON.stringify(this.values.imgs[index]));
            this.values.imgs.splice(index, 1);
            this.values.delete_photo_files.push({photo_path: deleted.path});

        },
    }
}
</script>

<style>

</style>