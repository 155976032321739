<template>
    <div>
        <v-row justify="center">
            <v-col  cols="12" md="5" class="py-2">
                <div class="resetPasswordProfile-rules">
                    <span class="resetPasswordProfile-dot">●</span> <span :class="`resetPasswordProfile-text-instruction ${values.password == '' ? '' : checkMayus ? 'resetPasswordProfile-green' : 'resetPasswordProfile-error'}`">Utiliza al menos una letra mayúscula</span> <br>
                    <span class="resetPasswordProfile-dot">●</span> <span :class="`resetPasswordProfile-text-instruction ${values.password == '' ? '' : checkMinus ? 'resetPasswordProfile-green' : 'resetPasswordProfile-error'}`">Utiliza al menos una letra minúscula</span><br>
                    <span class="resetPasswordProfile-dot">●</span> <span :class="`resetPasswordProfile-text-instruction ${values.password == '' ? '' : checkNumber ? 'resetPasswordProfile-green' : 'resetPasswordProfile-error'}`">Utiliza al menos un número</span><br>
                    <span class="resetPasswordProfile-dot">●</span> <span :class="`resetPasswordProfile-text-instruction ${values.password == '' ? '' : check8 ? 'resetPasswordProfile-green' : 'resetPasswordProfile-error'}`">Utiliza al menos 8 caractéres</span><br>
                </div>
            </v-col>
        </v-row>
        <v-row justify="space-around" class="mb-3">
            <v-col cols="12" md="5" class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.password = v.trim(); delete serverErrors.password}" 
                    :valueInput="values.password" 
                    :rule="[...rules.required, ...rules.atLeastMayus, ...rules.atLeastMinus,...rules.atLeastNumber, ...rules.atLeast8Chars]" 
                    label="Contraseña"
                    :errorMessages="serverErrors.password"
                    :count="true"
                    :prependInnerIcon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPass ? 'text' : 'password'"
                    @clickAppend="() => {showPass = !showPass}"
                    class="initial-transform-input"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row justify="space-around" class="mb-3">
            <v-col cols="12" md="5" class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.password_confirmation = v.trim(); delete serverErrors.password_confirmation}" 
                    :valueInput="values.password_confirmation" 
                     :rule="[...rules.required, ...rules.samePassword]" 
                    label="Confirmar contraseña"
                    :errorMessages="serverErrors.password_confirmation"
                    :count="true"
                    :type="showPass ? 'text' : 'password'"
                    @click:append="showPass = !showPass"
                    class="initial-transform-input"
                ></textFieldForm>
            </v-col>
        </v-row>
        
    
    </div>
</template>

<script>
/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
   
    data(){
        return {
            showPass: false,
            checkMayus: false,
            checkMinus: false,
            checkNumber: false,
            check8: false,
            rules: {
                required: [
					v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
                samePassword:[
                    (v) => v == this.values.password || "Las contraseñas no coinciden",
                ],
                atLeast8Chars:[
                    v => {
                        if(!v)
                        return 'El campo es requerido';
                        if(v.length >= 8) {
                            this.check8 = true;
                            return true
                        }
                        this.check8 = false;
                        return "Al menos 8 caractéres"
                    }
                ],
                atLeastNumber:[
                    v => {
                        if(!v)
                        return 'El campo es requerido';
                        if(v.length > 0) {
                            const pattern = /^(.*\d.*)$/;
                            this.checkNumber = pattern.test(v);
                            return pattern.test(v) || 'Al menos un número';
                        }
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true
                    }
                ],
                atLeastMinus:[
                    v => {
                        if(!v)
                        return 'El campo es requerido';
                        if(v.length > 0) {
                            const pattern = /^(.*[a-z].*)$/;
                            this.checkMinus = pattern.test(v);
                            return pattern.test(v) || 'Al menos una letra minúscula';
                        }
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true
                    }
                ],
                atLeastMayus:[
                    v => {
                        if(!v)
                        return 'El campo es requerido';
                        if(v.length > 0) {
                            const pattern = /^(.*[A-Z].*)$/;
                            this.checkMayus = pattern.test(v);
                            return pattern.test(v) || 'Al menos una letra mayúscula';
                        }
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true
                    }
                ],
                
				
            },
            serverItems: {
                company: [],
                employees: [],
                roles: [],
                employeesData:[],
            },
        }
    },
    watch: {
        values: function (vv) {
            //console.log(this.values, vv);
        }
    },
    mounted(){
       
    },
    methods: {
       
       
    }
}
</script>

<style lang="scss">
    .resetPasswordProfile{


        &-green{
            color: green !important;
        }

        &-error{
            // color: red !important;
        }
    }
</style>